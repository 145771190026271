import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import ClientsPanel from "../components/ClientsPanel";
import FullCard from "../components/FullCard";
import SidePanel from "../components/SidePanel";

export const pageQuery = graphql`
  {
    wp {
      page(id: "track-record", idType: URI) {
        id
        title
        trackRecordPage {
          clientsPanel {
            title
            clientsPanelLogos {
              clientsPanelImage {
                ...MediaItem
              }
            }
          }
        }
      }
      projects(where: { orderby: { field: DATE, order: ASC } }) {
        nodes {
          projects {
            projectBrochure {
              mediaItemUrl
            }
            projectDescription
            projectTagline
          }
          title
          featuredImage {
            node {
              ...MediaItem
            }
          }
        }
      }
    }
  }
`;

const trackRecord = ({ data }) => {
  const page = data.wp.page;
  const fields = data.wp.page.trackRecordPage;
  const projects = data.wp.projects.nodes;

  return (
    <Layout title={page.title}>
      <div className="relative 2xl:container">
        <SidePanel title={page.title} />
        <div className="flex flex-col items-center lg:items-end lg:p-36 -mt-44 lg:mt-0">
          {projects.map((project, i, array) => {
            let lastItem = false;
            if (array.length - 1 === i) {
              lastItem = true;
            }
            return (
              <FullCard
                key={i}
                linkLabel="Download brochure"
                linkDestination={
                  project.projects.projectBrochure
                    ? project.projects.projectBrochure.mediaItemUrl
                    : null
                }
                cardImage={project.featuredImage}
                cardTitle={project.title}
                cardDescription={project.projects.projectDescription}
                cardSubheading={project.projects.projectTagline}
                lastItem={lastItem}
              />
            );
          })}
        </div>
      </div>
      <ClientsPanel clientsPanelInfo={fields.clientsPanel} />
    </Layout>
  );
};

export default trackRecord;
