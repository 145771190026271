import React from "react";

import Image from "./Image";

const ClientsPanel = ({ clientsPanelInfo }) => {
  return (
    <div className="bg-cerulean p-32 text-white py-24 px-12 md:p-32">
      <div className="2xl:container">
        <p className="font-bold uppercase text-center text-5xl leading-tight mb-16 md:mb-20">
          {clientsPanelInfo.title}
        </p>
        {clientsPanelInfo.clientsPanelLogos && (
          <div className="flex flex-col md:flex-row justify-center md:mx-auto md:max-w-6xl md:justify-between space-y-16 md:space-y-0 md:space-x-36">
            {clientsPanelInfo.clientsPanelLogos.map((item, i) => {
              return (
                <div className="grid place-items-center">
                  <Image
                    image={item.clientsPanelImage}
                    alt={item.clientsPanelImage.altText}
                    className={`w-48 object-contain md:w-40`}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientsPanel;
